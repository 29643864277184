<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="账户明细" class="margin-bottom margin-top-xs"></el-page-header>
		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="order_no" label="订单编号" width="230">
			</el-table-column>
			<el-table-column prop="order_origin" label="来源" width="100">
				<template slot-scope="scope">
					<div v-if="scope.row.order_origin==1">小程序</div>
					<div v-if="scope.row.order_origin==2">线下门店</div>
				</template>
			</el-table-column>
			<el-table-column prop="actual_fee" label="金额" width="150"></el-table-column>
			<el-table-column prop="pay_method" label="支付方式" width="100">
				<template slot-scope="scope">
					<!-- // 支付类型  1 微信  2 支付宝  3 会员余额 4 现金 -->
					<div v-if="scope.row.pay_method==1">微信</div>
					<div v-if="scope.row.pay_method==2">支付宝</div>
					<div v-if="scope.row.pay_method==3">会员余额</div>
					<div v-if="scope.row.pay_method==4">现金</div>
				</template>
			</el-table-column>
			<el-table-column prop="desc" label="描述" width="200">
			</el-table-column>
			<el-table-column prop="remark" label="备注"  width="200"></el-table-column>
			<el-table-column prop="created_at" label="时间">
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false,
				searchForm: {
					memberId: this.$route.params.memberId,
					page: 1,
					page_size: 10
				},
				total: 0,
				tableData: []
			};
		},
		mounted() {
			this.search();
		},
		methods: {
			search(){
				const loading = this.$loading()
				// 查询列表
				this.$api.member.getAccountDetail({
					id: this.searchForm.memberId,
					page: this.searchForm.page,
					page_size: this.searchForm.page_size
				}).then(res => {
					this.total = res.data.count;
					this.tableData = res.data.list;
					loading.close()
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
		},
	};
</script>

<style scoped lang="scss">

</style>
